%input--grey {
  background: fade_out($color-grey, 0.875);
  border-color: fade_out($color-grey, 0.75);
}

.button {
  $button: &;
  transition: all 0.25s;
  cursor: pointer;

  &:hover {
    transition: all 0.25s;
    @extend #{$button}--inverted;
  }

  &--blue {
    background: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }

  &--red {
    background: $color-red;
    border-color: $color-red;
    color: $color-white;
  }
  &--green {
    background: $color-green;
    border-color: $color-green;
    color: $color-white;
  }
  &--grey {
    background: #fafafa;
    border-color: darken(#fafafa, 10%);
    color: $color-grey--dark;
  }

  &--yellow {
    background: $color-yellow;
    border-color: $color-yellow;
    color: $color-white;
  }

  &--link {
    background: none;
    border-color: transparent;
    color: $color-blue;
    &:hover {
      background-color: transparent;
    }
  }

  &--inverted {
    background: $color-white;
    color: $color-grey--dark;
    text-shadow: none;

    &#{$button}--blue {
      border-color: fade_out($color-blue, 0.6);
      color: $color-blue;
    }

    &#{$button}--red {
      border-color: fade_out($color-red, 0.5);
      color: $color-red;
    }

    &#{$button}--green {
      border-color: fade_out($color-green, 0.5);
      color: darken($color-green, 8%);
    }

    &#{$button}--grey {
      border-color: fade_out($color-grey, 0.5);
      color: $color-grey;
    }

    &#{$button}--yellow {
      border-color: fade_out($color-yellow, 0.5);
      color: darken($color-yellow, 5%);
    }
  }

  &--spin-child > * {
    -webkit-animation: spin 0.75s linear infinite;
    -moz-animation: spin 0.75s linear infinite;
    animation: spin 0.75s linear infinite;
  }

  &__status {
    position: absolute;
    right: 0.25em;
    top: 0.25em;
  }

  > span {
    color: inherit;
  }
}

%hr--divider {
  margin: 1em 0 2em;
  border-color: #eee;
  border-style: solid;
  border-bottom: 0;
}
.hr--divider {
  @extend %hr--divider;
}

%tabs-settings {
  &--primary {
    > div.menu.ui.attached {
      background: fade_out($color-blue, 0.75);
    }
  }

  &--unstyled {
    > div.menu.top.attached {
      background: fade_out($color-grey--dark, 0.75);
      border: 0;
      min-height: 0;
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      .item {
        margin: 0;
        width: 50%;
        justify-content: center;
        color: $color-grey--dark;
        border-radius: 0;
        padding: 1em;

        &:not(.active) > i.icon {
          color: $color-grey !important;
        }

        &.active {
          background: $color-grey--light;
          border-bottom: 0;
        }
      }
    }
    > div.bottom {
      border-color: transparent;
      background: $color-grey--light;
    }
  }

  &--striped {
    .tab .row:nth-child(even) {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  > .menu {
    &.top.attached {
      background: fade_out($color-blue, 0.9);
      & > .item {
        .icon {
          color: $color-grey--dark;
        }

        &.active {
          .icon {
            color: $color-blue;
          }
        }
      }
    }

    &.tabular {
      background-color: fade_out($color-grey, 0.85);
      border-radius: 5px;

      .item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        justify-content: center;
        color: $color-grey--dark;

        &.active {
          font-weight: normal;
          color: rgba(0, 0, 0, 0.7);

          i {
            color: $color-blue;
          }
        }

        @include phone {
          font-size: 0;
          i {
            font-size: 1.25rem;
            padding: 0.75em;
            margin: 0;
            box-sizing: content-box;
          }
        }
      }

      & + .attached {
        border: 0;
        padding-top: 40px;

        .ui.header:first-child {
          margin: 0;
        }
      }
    }
  }
}

.articles-list {
  $articles-list: &;

  #{$articles-list}__item:not(.default) {
    border-color: transparent;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: -1em;
    margin-right: -1em;

    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.033) !important;
    }
  }

  #{$articles-list}__text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .ant-list-item-meta-description {
      max-height: 1.5em;
      overflow: hidden;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  #{$articles-list}__date {
    text-align: center;
    margin: 0;
    font-size: 1.25em;
  }

  #{$articles-list}__text {
    .ant-list-item-meta-title {
      line-height: 1.25;
      font-size: 14px;
      margin-top: 2px;
      margin-bottom: 4px;
    }
  }

  #{$articles-list}__icon {
    margin: 0.25em 0;
    font-size: 2em;
    color: #ccc;
  }

  #{$articles-list}__icon-block {
    display: flex;
    flex-direction: column;
    margin-right: 0.5em;
  }

  #{$articles-list}__status {
    margin: 0 1em;
    text-align: center;
    cursor: pointer;
  }

  &__filter {
    &.box {
      display: none;
      justify-content: space-between;
      align-items: center;
      padding: 0.5em 1em !important;
      margin: -0.75em 0 1em;
      &--shown {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        > * {
          margin-right: 10px !important;
        }
        .ant-calendar-picker {
          margin-right: 0px !important;
        }
      }
    }

    &-button {
      //box-shadow: none;
      color: $color-grey;
      border-color: $color-grey--light;
      background-color: rgba(0, 0, 0, 0.025);
    }

    &--vertical {
      flex-direction: column;
      > * {
        width: 100%;
        .ant-select,
        .ant-calendar-picker {
          width: 100% !important;
        }
      }
    }

    &-enter {
      opacity: 0;
      display: flex;
    }
    &-enter-done {
      opacity: 1;
      display: flex;
    }
    &-exit {
      opacity: 0;
    }
    &-exit-done {
      opacity: 0;
      display: none;
    }
  }
}

.nth-list {
  &--reversed {
    > *:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.01);
    }
  }

  > *:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.025);
  }
}

.box {
  text-align: left;
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid $color-grey--light;
  background-color: fade_out($color-grey--light, 0.5);
  transition: all 0.5s;

  &--green {
    border-color: fade_out($color-green, 0.5);
    background-color: fade_out($color-green, 0.75);
  }

  &--blue {
    border-color: fade_out($color-blue, 0.75);
    background-color: fade_out($color-blue, 0.875);
  }

  &--white {
    border-color: rgba(255, 255, 255, 0.9);
    background-color: #fff;
  }

  &__title {
    opacity: 0.75;
    font-size: 1.33em;
  }

  &__description {
    display: flex;
    line-height: 1.2;
    justify-content: space-between;
    align-items: center;

    @include phone {
      flex-direction: column;
      text-align: center;
    }
  }

  &__action-button {
    margin-left: 1.5em;

    @include phone {
      margin: 1em 1em 0.5em;
    }
  }
}

.changed-fields {
  margin: 1.5em 0;

  &__block {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }

    &-label {
      font-weight: bold;
      text-align: center;
      margin-bottom: 1em;
    }
  }

  &__row {
    padding: 0.25em 1em;
  }

  &__result {
    .ant-statistic-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        &:first-child {
          font-size: 0.75em;
          color: $color-grey;
        }
        i {
          font-size: 0.75em;
        }
        &:last-child {
          font-size: 1.125em;
          font-weight: bold;
          color: $color-green;
        }
      }
    }
  }
}

.look-at-me {
  animation-name: bounce;
  animation-timing-function: steps(7);
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

.dots-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.moving-background {
  -webkit-animation: progress-bar-stripes 10s linear infinite;
  animation: progress-bar-stripes 10s linear infinite;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.code {
  padding: 3px 6px;
  border-radius: 9px;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New;
  border: 1px solid fade_out($color-blue, 0.75);
  color: fade_out($color-blue, 0.25);
  background-color: fade_out($color-blue, 0.925);
}

.spin {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
