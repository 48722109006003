.footer {
  $this: &;

  display: flex;
  justify-content: space-between;

  @include phone {
    margin-top: 1em;
    flex-direction: column;
  }

  &__text {
    color: $font-color;
    font-size: $font-size;

    & + #{$this}__text {
      margin-left: $gutter / 2;
    }
  }

  &__info {
    display: flex;
    justify-content: flex-end;

    @include phone {
      justify-content: space-between;

      a {
        font-size: 0.975em;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
