.page {
  &-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100vh;
    max-width: none;
    min-width: 0;
    width: 100%;
    margin: 0 auto;

    &-body {
      flex: 1;
    }
  }

  &-layout {
    $layout: &;

    // label: MEMBER-AREA only
    &--member {
      #{$layout}__content {
        @include phone {
          // removing menu padding
          padding-left: 20px;
        }
      }
    }

    &__sidebar {
      transition: all 0.25s;
      background: none;
    }

    &__content {
      transition: all 0.25s;
      padding: 0 20px;

      @include phone {
        padding-left: 60px;
      }

      > header,
      > main {
        position: relative;
      }
    }
  }
}

.layout-wrapper {
  &:not(.fullscreen),
  &.fullscreen--disabled {
    margin-left: 0;
    margin-right: auto;
    max-width: 960px;
    width: 100%;
  }
}

.fullscreen--disabled {
  max-width: 960px;
  margin: auto;
}
