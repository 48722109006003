.gallery {
  flex: 1;
  height: 100%;

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fafafa;
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    svg {
      position: relative;
      width: 50px;
      height: 50px;
      padding: calc(50% - 25px);
      box-sizing: content-box;
    }
  }

  &__sticky {
    position: sticky;
    top: 20px;

    &--desktop {
      padding-top: 59%;
    }

    &--mobile {
      top: 0;
    }
  }

  &__desktop,
  &__mobile {
    position: relative;
    overflow: hidden;

    &__img {
      position: absolute;
      top: 0;

      img {
        width: 100%;
      }
    }

    &__content {
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
      z-index: 2;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 10px;
    }
  }

  &__desktop {
    padding: 3.5% 11.33% 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__img {
      left: 0;
      right: 0;
    }

    &__content {
      position: relative;
      height: 87.6%;
    }
  }

  &__mobile {
    height: 880px;

    &__img {
      width: 450px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__content {
      position: absolute;
      width: 390.6px;
      height: 641.3px;
      top: 116.3px;
      left: 50%;
      border-radius: 3.4px;
      transform: translateX(-50%);
    }
  }
}
