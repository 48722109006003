.breadcrumbs {
  display: flex;
  padding: 0.5em 0;
  list-style: none;
  margin: 0;
  font-size: 1em;
  flex-wrap: wrap;
  line-height: 1.2;

  @include phone {
    font-size: 12px;
  }

  &__item {
    $this: &;

    a {
      transition: all 0.15s ease-out;

      &:hover {
        color: darken($color-blue, 25%);
      }
    }

    > a,
    > span {
      display: inline-block;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-weight: bold;
    }
    i {
      font-style: normal;
      font-weight: normal;
    }

    & + #{$this} {
      position: relative;
      margin-left: $gutter;

      &:before {
        content: '/';
        position: absolute;
        text-align: center;
        width: 4px;
        left: calc(-2px - #{$gutter}/ 2);
        top: 0;
        color: $color-grey;
      }
    }

    &--active a {
      cursor: default;
      color: $color-grey;

      &:hover {
        color: $color-grey;
      }
    }
  }
}
