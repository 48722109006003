th {
  &.tooltip-wrapper {
    position: relative;
  }
}

.tooltip {
  position: absolute;
  left: 3px;
  top: 3px;
  color: $color-green;
  padding: 1px;
  border-radius: 50%;
}
