// PAGES
.page-wrapper {
  > .header {
    padding: ($gutter / 2) $gutter;
  }

  .page {
    &-informer {
      // Informer edit page
      &__content {
        box-shadow: none !important;
        overflow: initial;

        @include phone {
          flex-direction: column-reverse !important;
        }

        .header {
          margin-top: 0;
        }

        .input-label-wrapper {
          margin: 0.5em 0;
        }

        input[type='text'],
        input[type='number'],
        .selection.dropdown[role='listbox'] {
          @extend %input--grey;
        }

        hr {
          @extend %hr--divider;
        }

        .tabs-settings {
          @extend %tabs-settings;
        }
      }

      &__gallery {
        @include phone {
          display: none !important;
          box-shadow: none !important;
        }
      }
    }
    &-publisher-settings {
      &__stages {
        display: flex;
        margin-bottom: 1em;

        .ui.segment {
          width: 50%;
        }
      }

      &__steps.steps {
        .step {
          background: $color-grey--light;
          padding: 1em 2em;
          justify-content: space-between;
          &:after {
            background: $color-grey--light;
          }

          &:before {
            font-size: 3em;
            margin: 0;
          }

          &.active {
            background: $color-blue;

            &:before {
              color: $color-white;
            }
            &:after {
              background: $color-blue;
            }
            .title {
              color: $color-white;
            }
            .description {
              color: $color-grey--light;
            }
          }

          .dropdown {
            .text {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 5em;
            }
          }
        }
      }

      &__search {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 1em;

        &-step1 input {
          border-radius: 0.25em 0 0 0.25em;
        }
        &-step2 .ant-select-selection {
          border-radius: 0;
          margin: 0 -1px;
        }
        &-step3 {
          border-radius: 0 0.25em 0.25em 0;
        }

        button {
          i {
            opacity: 0.33;
          }
        }

        &-list {
          width: 100%;
        }

        &-item {
          p {
            margin-bottom: 0;
            color: $color-grey;
          }
        }

        > .checkbox {
          margin-left: 1em;
          label {
            color: $color-grey--dark;
          }
        }
      }

      &__block {
        position: relative;
        min-height: 10em;
        margin-top: 2em;
      }

      &__loader {
        position: fixed;
        top: 50%;
        left: 50%;
      }

      &__articles {
        &-added {
          > div.item {
            padding: 0.5em;

            &:nth-child(even) {
              background: fade_out($color-grey, 0.925) !important;
            }
          }

          .description {
            margin: 0.5em;
            opacity: 0.5;
            max-height: 2.4em;
            overflow: hidden;
            margin-bottom: 0.5em;
            font-size: 0.875em;

            > * {
              margin: 0;
              line-height: 1.2;
            }
          }

          .dropdown .text {
            min-width: 2em;
          }
        }
      }

      .ui.segment:not(.tab) {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em 1em 3em;
      }

      [stage] {
        position: relative;

        .loader {
          right: 0;
          left: auto;
        }

        &:before {
          content: attr(stage);
          position: absolute;
          left: 0.5em;
          top: 0.5em;
          font-size: 2em;
          font-weight: bold;
          opacity: 0.25;
        }
      }
    }
    &-main-settings {
      .tabs-settings {
        @extend %tabs-settings;
        @extend %tabs-settings--striped;
      }
      hr {
        @extend %hr--divider;
      }
    }
  }
}
