.ant {
  $ant: &;

  &-layout {
    $layout: &;
    background: $color-white;

    #{$layout}-content {
      min-height: auto;
    }

    #{$layout}-sider {
      padding-top: 2em;

      &-dark {
        background: #333;

        #{$ant}-menu {
          &-inline-collapsed i {
            color: $color-green !important;
          }

          &-sub {
            background-color: rgba(0, 0, 0, 0.125) !important;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08) inset;
          }

          &-submenu {
            border-bottom: 1px solid fade_out($color-white, 0.95);

            &-open {
              background: rgba(0, 0, 0, 0.075);
              color: rgba(255, 255, 255, 0.65);
            }

            &-title i.anticon {
              color: $color-green;
            }
          }

          &-item {
            &:after {
              border: 2px solid $color-green !important;
              border-radius: 5px;
            }

            &-selected {
              color: $color-white !important;
              background-color: fade_out($color-green, 0.85);

              > a {
                color: #fff;
                span {
                  color: #fff;
                }
              }
            }

            &-sub {
              background: rgba(0, 0, 0, 0.25);
            }

            &-submenu {
              border-bottom: 1px solid fade_out($color-white, 0.925);

              &-open,
              &-selected {
                color: rgba(255, 255, 255, 0.65);
              }
            }

            i.anticon {
              color: $color-green;
            }
          }
        }

        #{$ant}-layout-sider-trigger {
          color: rgba(255, 255, 255, 0.25);
        }
      }

      &-light {
        background-color: $color-grey--light;

        #{$ant}-menu {
          &-inline-collapsed i {
            color: $color-blue !important;
          }

          &-sub {
            background-color: rgba(0, 0, 0, 0.033) !important;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;

            #{$ant}-menu-item {
              border-color: rgba(0, 0, 0, 0.05);
            }
          }

          &-submenu {
            &-selected {
              color: $color-blue;
            }

            &-open {
              //background: rgba(0, 0, 0, 0.05);
              color: $color-grey--dark;
            }

            &-title {
              &:hover {
                color: $color-blue;

                i {
                  &:before,
                  &:after {
                    background: $color-blue;
                  }
                }
              }
            }
            i.anticon {
              color: $color-blue;
            }
          }

          &-item {
            > a {
              &:hover {
                color: $color-blue;
              }
            }

            &-selected {
              color: $color-white !important;
              background-color: fade_out($color-blue, 0.5) !important;

              &:after {
                border: 2px solid $color-blue !important;
                border-radius: 5px;
              }

              > a {
                color: $color-white;
                span {
                  color: $color-white;
                }
              }
            }

            &-sub {
              background: rgba(0, 0, 0, 0.25);
            }

            &-submenu {
              border-bottom: 1px solid fade_out($color-white, 0.925);

              &-open,
              &-selected {
                color: rgba(255, 255, 255, 0.65);
              }
            }

            i.anticon {
              color: $color-blue;
            }
          }
        }

        #{$ant}-layout-sider-trigger {
          color: rgba(0, 0, 0, 0.25);
        }
      }

      @include phone {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 10;
      }

      &-children {
        position: relative;
      }

      // LABEL: COLLAPSED
      &-collapsed {
        flex: 0 0 40px !important;
        max-width: 40px !important;
        min-width: 40px !important;
        width: 40px !important;

        #{$layout}-sider-trigger {
          width: 40px !important;
        }

        #{$ant}-menu {
          &-submenu {
            &-selected {
              > #{$ant}-menu.ant-menu-hidden {
                display: none;
              }
            }
          }

          &-submenu-selected,
          &-item-selected {
            i.anticon {
              vertical-align: middle;
            }
          }

          #{$ant}-menu-item {
            padding: 0 10px !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            &:hover {
              i {
                color: $color-grey--dark !important;
                transition: all 0.25s;
              }
            }
          }

          &-inline-collapsed {
            width: 40px;

            #{$ant}-menu-submenu-title {
              padding: 0 10px !important;
            }

            i {
              text-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
            }
          }
        }

        #{$ant}-layout-sider-trigger {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      .theme-switcher {
        position: absolute;
        top: -23px;
        right: 8px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #aaa;
        opacity: 0.75;

        &:hover {
          .theme-switcher__title span {
            margin-right: 0.5em;
            transition: margin-right 0.5s;
          }
        }

        &__title {
          overflow-x: hidden;
          position: relative;
          text-align: right;
          font-size: 12px;

          span {
            margin-right: -100%;
            transition: margin-right 0.5s;
            white-space: nowrap;
          }
        }
      }

      #{$ant}-layout-sider-trigger {
        background: none;
        border-radius: 5px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      #{$ant}-layout-sider-children {
        position: relative;
      }

      #{$ant}-menu {
        $menu: &;
        background: none;

        &-item {
          border-bottom: 1px solid fade_out($color-white, 0.925);
          margin: 0;
          position: relative;

          &--custom {
            position: absolute;
            left: 0;
            padding-left: 24px;
          }

          &:last-child {
            border-bottom-color: transparent;
          }

          .ant-badge {
            max-width: calc(100% - 1.5em);
            .dots-overflow {
            }
          }

          #{$ant}-btn-link {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.125);
            border-radius: 0;
            i {
              color: fade_out($color-white, 0.5);
              font-size: 0.875em;
            }
          }

          .anticon-plus-circle {
            margin-right: 0;
          }

          > a {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          i {
            font-size: 20px;
            vertical-align: middle;
          }
        }

        &-submenu-title {
          padding: 0;
          margin: 0;

          i.ant-menu-submenu-arrow {
            opacity: 0.25;
          }

          i.anticon {
            font-size: 20px;
            vertical-align: middle;
          }
        }
      }

      #{$ant}-menu-sub {
        #{$ant}-menu-item {
          padding-left: 54px !important;

          a > span:before {
            content: '- ';
          }
        }
      }
    }

    #{$layout}-header {
      background: none;
      height: auto;
      padding: 0 0 1em;
      line-height: normal;
    }

    #{$layout}-footer {
      background: none;
    }
  }

  &-menu {
    $menu: &;

    &-submenu-popup {
      &#{$menu}-dark #{$menu} {
        background-color: #333;
        #{$menu}-item {
          border-color: rgba(255, 255, 255, 0.1);

          &-selected {
            background-color: fade_out($color-green, 0.875);
          }

          > a {
            color: $color-grey--light;
          }
        }
      }

      #{$menu} {
        background-color: $color-white;
        border-top-left-radius: 0;
        overflow: hidden;

        #{$menu}-item {
          color: $color-blue;
          border-bottom: 1px solid $color-grey--light;
          margin: 0;

          > a {
            color: $color-blue;
          }

          &:last-child {
            border-bottom: 0;
          }

          &-selected {
            background-color: fade_out($color-blue, 0.05);
            > a {
              color: $color-white;
            }
          }
        }
      }
    }
  }

  &-drawer {
    $drawer: &;

    #{$drawer}-header {
      background-color: #333;
      border-radius: 0;
      > * {
        color: $color-white;
      }
    }
  }

  &-card {
    border-radius: 0.5em;
    background: fade_out($color-grey--light, 0.75);

    &:not(.default) {
      border-radius: 0.5em;
      background: fade_out($color-grey--light, 0.75);
    }

    &-head {
      background: $color-grey--light;
    }

    #{$ant}-tabs {
      > .ant-tabs-content {
        margin-top: -16px;
      }

      > .ant-tabs-content > .ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
        border: 1px solid #eee;
        border-top: 0;
      }

      > .ant-tabs-bar {
        //border-color: #fff;
      }

      > .ant-tabs-bar .ant-tabs-tab {
        //border-color: transparent;
        //background: transparent;
      }

      > .ant-tabs-bar .ant-tabs-tab-active {
        //border-color: #fff;
        //background: #fff;
      }
    }
  }

  &-input,
  &-input-number,
  &-select-selection,
  &-radio-inner,
  &-checkbox-inner {
    background-color: fade_out($color-blue, 0.97);
    border-color: fade_out($color-blue, 0.6);

    .error & {
      border-color: fade_out($color-red, 0.5);
    }
  }

  &-radio {
    $radio: &;

    .ant-radio-inner {
      border-color: $color-blue;

      &:after {
        background-color: $color-blue;
      }
    }

    &-button-wrapper {
      text-align: center;
    }

    &-group {
      &-solid {
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          background: fade_out($color-blue, 0.2);
          border-color: fade_out($color-blue, 0.2);
          box-shadow: -1px 0 0 0 fade_out($color-blue, 0.2);

          &:hover {
            background: fade_out($color-blue, 0.1);
            border-color: fade_out($color-blue, 0.1);
            box-shadow: -1px 0 0 0 fade_out($color-blue, 0.1);
          }
        }
      }
    }
  }

  &-radio-button-wrapper {
    &:hover {
      color: $color-blue;
    }
  }

  &-btn {
    $btn: &;
    @extend .button;

    &[disabled],
    &:disabled,
    &:disabled:hover {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    //[type="primary"],
    &#{$btn}-primary {
      @extend .button--blue;
    }
    //[type="button"]
    &#{$btn}-button {
      @extend .button--green;
    }
    //[type="default"]
    &#{$btn}-default {
      @extend .button--grey;
    }
    //[type="dashed"]
    &#{$btn}-dashed {
      @extend .button--inverted;
    }
    //[type="danger"]
    &#{$btn}-danger {
      @extend .button--red;
    }
    //[type="warning"]
    &#{$btn}-warning {
      @extend .button--yellow;
    }
    //[type="success"]
    &#{$btn}-success {
      @extend .button--green;
    }
    //[type="error"]
    &#{$btn}-error {
      @extend .button--red;
    }
    //[type="link"]
    &#{$btn}-link {
      @extend .button--link;

      &:disabled {
        border: 0;
        background-color: transparent;
      }
    }
  }

  &-pagination {
    &-item {
      &-active {
        &:hover,
        &:focus,
        &:active {
          border-color: $color-blue;
          background: fade_out($color-blue, 0.95);

          > * {
            color: $color-blue !important;
          }
        }

        &-active {
          border-color: fade_out($color-blue, 0.75);
          background: fade_out($color-blue, 0.95);

          a {
            color: $color-blue;
          }
        }
      }
    }
  }

  &-table {
    $table: &;
    color: #555;
    overflow-x: auto;
    text-align: center;

    &--auto-size {
      width: auto;
      max-width: 100%;
      display: inline-block;

      tbody.ant-table-tbody > tr > td {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }

    &--empty {
      width: 100%;
    }

    #{$table}-thead {
      font-size: 12px;

      th {
        font-weight: 600;
        padding: 8px 6px 8px 12px;
        text-transform: uppercase;
        font-size: 11px;
        text-align: center !important;

        &.ant-table-column--vertical {
          vertical-align: bottom;

          .ant-table-column-title {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            white-space: nowrap;
            display: inline-block;
            overflow: visible;
          }
        }

        .ant-table-column-sorter .ant-table-column-sorter-inner {
          i.ant-table-column-sorter-up,
          i.ant-table-column-sorter-down {
            font-size: 10px;

            &.on {
              color: $color-blue;
            }
          }
        }

        .ant-table-filter-selected.anticon-filter {
          color: $color-blue;
        }
      }

      #{$table}-column {
        &-sort {
          background: fade_out($color-green, 0.9);
        }
        &-sorters {
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
        &-title {
          position: inherit !important;
        }

        &--yellow {
          background-color: fade_out($color-yellow, 0.825);
        }
        &--blue {
          background-color: fade_out($color-blue, 0.825);
        }
        &--red {
          background-color: fade_out($color-red, 0.825);
        }
        &--green {
          background-color: fade_out($color-green, 0.825);
        }
        &--grey {
          background-color: fade_out($color-grey, 0.825);
        }
        &--grey-light {
          background-color: fade_out($color-grey, 0.875);
        }
      }
    }

    #{$table}-tbody {
      tr > td {
        padding: 7px 10px;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;

        .table-cell-subtitle {
          display: flex;
          align-items: center;
          padding: 0;
          margin: 0;
          height: 1.4em;
          line-height: 1.4;
          font-size: 12px;
          opacity: 0.75;
        }
        .table-cell--number {
          white-space: nowrap;
          text-align: right;
        }
        .table-cell--left {
          text-align: left;
        }
        .table-cell--right {
          text-align: right;
        }
      }

      #{$table}-row {
        &--verification {
          background-color: fade_out($color-yellow, 0.875);
        }
        &--moderation,
        &--pending {
          background-color: fade_out($color-blue, 0.875);
        }
        &--blocked,
        &--suspended,
        &--suspending {
          background-color: fade_out($color-red, 0.875);
        }
        &--active {
          background-color: fade_out($color-green, 0.875);
        }
        &--finished,
        &--analytics,
        &--deleted {
          background-color: fade_out($color-grey, 0.875);
        }

        &--warning {
          background-color: fade_out($color-yellow, 0.65);
        }

        &--not_active,
        &-selected td {
          background-color: #fff;
        }
      }

      #{$table}-column {
        &-sort {
          background: fade_out($color-green, 0.9);
        }

        &--yellow {
          background-color: fade_out($color-yellow, 0.875);
        }
        &--blue {
          background-color: fade_out($color-blue, 0.875);
        }
        &--red {
          background-color: fade_out($color-red, 0.875);
        }
        &--green {
          background-color: fade_out($color-green, 0.875);
        }
        &--grey {
          background-color: fade_out($color-grey, 0.875);
        }
        &--grey-light {
          background-color: fade_out($color-grey, 0.925);
        }
      }

      #{$table}-status > div > svg {
        cursor: pointer;
        margin-left: 10px;
        font-size: 1.125em;
      }
    }

    #{$table}-footer {
      font-weight: 600;
      text-align: center;
      color: $color-grey--dark;

      &-row {
        background: fade_out($color-green, 0.975);

        > td:first-child {
          font-size: 13px;
          color: #555;
          text-transform: uppercase;
        }
      }
    }

    #{$table}-scroll {
      overflow-x: auto;
    }
    #{$table}-fixed {
      &-left {
        -webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.04) !important;
        box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.04) !important;
      }
      &-right {
        -webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.04) !important;
        box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.04) !important;
      }

      &-columns-in-body {
        white-space: nowrap;
      }
    }

    .ant-btn-group {
      margin: -6px -10px;

      .ant-btn-success {
        //@extend .moving-background;
        color: $color-green;
        background-color: $color-white;
        border-color: #eee;
      }
      .ant-btn-error {
        //@extend .moving-background;
        color: $color-red;
        background-color: $color-white;
        border-color: #eee;
      }

      > * {
        border-color: $color-grey--light;
        font-size: 1.25em;
        background-color: #fafafa;

        &:hover {
          background-color: #f5f5f5;
          border-color: $color-grey--light;
        }

        &[disabled] {
          color: $color-grey;
          opacity: 0.875;
          cursor: not-allowed;

          &:hover {
            cursor: not-allowed;
          }
        }
      }
      > span button {
        margin-top: -1px;
      }
    }
  }

  &-table-filter {
    &-dropdown-btns {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      > a {
        color: $color-blue;
        &.confirm {
          background-color: $color-blue;
          border-radius: 4px;
          padding: 0px 6px;
          color: #fff;
        }
      }
    }
  }

  &-steps {
    $steps: &;

    #{$steps}-item {
      &-process {
        .ant-steps-item-icon {
          background: $color-blue;
          color: $color-white;
          border-color: $color-blue;
        }
      }

      &-finish {
        .ant-steps-item-icon {
          background: $color-white;
          border-color: $color-blue;
          > .ant-steps-icon {
            color: $color-blue;
          }
        }
        .ant-steps-item-tail:after {
          background-color: $color-blue;
        }
      }

      &-wait {
        border-color: $color-blue;
      }

      &-tail:after {
        background-color: $color-white;
      }
    }
  }

  &-tabs {
    $tabs: &;

    #{$tabs}-tab:hover {
      color: fade_out($color-blue, 0.2);
    }
    &#{$tabs}-line {
      #{$tabs}-tab-active#{$tabs}-tab {
        color: $color-blue;
      }
      .ant-tabs-ink-bar {
        background-color: $color-blue;
      }
    }

    &#{$tabs}-card {
      .ant-tabs-card-bar .ant-tabs-tab {
        background-color: #f5f5f5;

        &-active {
          background-color: #ffffff;
          color: $color-blue;
        }
      }
    }
  }

  &-select-dropdown-menu-item {
    &.disabled-option {
      background-color: fade_out($color-red, 0.8);

      &:hover {
        background-color: fade_out($color-red, 0.7);
      }
    }
  }

  &-list {
    #{$ant}-list-item {
      &-meta {
        margin: 0;

        &-avatar {
          margin-top: 5px;
          .ant-avatar {
            border-radius: 8px;
          }
        }
      }

      &-meta-title {
        margin: 0;
      }

      &-meta-description {
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }

  &-transfer-list {
    overflow: auto;
  }

  &-transfer-list-body {
    overflow: auto;
  }

  &-checkbox-input {
    &:focus {
      & + .ant-checkbox-inner {
        border-color: $color-blue;
      }
    }
  }

  &-tree-checkbox,
  &-checkbox,
  &-checkbox-wrapper {
    &:hover {
      .ant-tree-checkbox-inner,
      .ant-checkbox-inner {
        border-color: $color-blue;
      }
    }
  }

  &-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after,
  &-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $color-blue;
  }

  &-tree-checkbox-checked,
  &-checkbox-checked {
    .ant-checkbox-inner,
    .ant-tree-checkbox-inner {
      background-color: $color-blue;
      border-color: $color-blue;
    }

    &:after {
      border-color: $color-blue;
    }
  }

  &-tree-checkbox-disabled,
  &-checkbox-disabled {
    .ant-checkbox-inner,
    .ant-tree-checkbox-inner {
      background-color: #fafafa;
      border-color: #d9d9d9;
    }
  }

  &:hover {
    &:after {
      border-color: $color-blue;
    }
  }

  &-form {
    &-item {
      margin-bottom: 15px;
    }

    &-item-label {
      width: 100%;
      height: 20px;
      line-height: 20px;
      text-align: left;

      label {
        margin-left: 5px;
        font-size: 12px;
        color: $color-grey;

        &:after {
          content: '';
        }
      }
    }
  }

  &-loader {
    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
  }

  &-tabs-tabpane {
    padding: 5px;
  }

  &-list-item-meta {
    $meta: &;

    &.elipsis {
      #{$meta}-content {
        overflow: hidden;
      }

      overflow: hidden;
    }

    #{$meta}-title {
      a {
        color: $color-blue;

        &:hover {
          color: darken($color-blue, 15%);
        }
      }
    }
  }

  &-alert {
    $alert: &;

    &#{$alert}-info {
      background-color: fade_out($color-blue, 0.9);
      border-color: fade_out($color-blue, 0.6);
    }

    #{$alert}-icon {
      color: $color-blue;
    }
  }

  &-tooltip {
    z-index: 10;
  }

  &-popover {
    z-index: 11;

    &-placement-topLeft,
    &-placement-topRight,
    &-placement-bottom,
    &-placement-bottomLeft,
    &-placement-bottomRight {
      .ant-popover-arrow {
        //border-color: $color-blue !important;
      }
    }

    &-title {
      position: relative;
      background-color: #f7f7f7 !important;
      border-radius: 4px 4px 0 0;

      &__icon {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        color: $color-blue;
      }
    }
  }

  &-table-small {
    border: 0;
  }

  &-notification-notice {
    &-description {
      white-space: pre-wrap;
    }
  }

  &-spin {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 10;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;

    &-dot-item {
      background-color: $color-grey;
    }
  }

  &-badge {
    &-count {
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      right: -0.5em;
      padding: 0 5px;
      box-shadow: none;
      font-weight: bold;
      border: 1px solid transparent;
    }

    &-dot {
      @extend .look-at-me;
      right: -0.5em;
      box-shadow: none;
    }
  }

  &-select {
    &#{$ant}-select-auto-complete #{$ant}-input:focus,
    &#{$ant}-select-auto-complete #{$ant}-input:hover {
      border-color: fade_out($color-blue, 0.4);
    }

    &-selection--multiple {
      input[autocomplete] {
        padding: 0;
        margin-left: 20px;
      }
    }
  }

  &-switch {
    &-checked {
      background-color: $color-green;
    }
  }
}
