.validator-field {
  position: relative;
  width: 100%;

  .error {
    width: 100%;
    display: block;
    margin-top: 5px;
    color: $color-red;
    padding: 7px 5px;
    font-family: sans-serif;
    position: absolute;
    top: 30px;
    height: fit-content;
  }

  .popup.ui {
    margin-bottom: -100%;
    top: auto;
    background: $color-red;
    color: $color-white;

    &:before {
      background: $color-red;
    }
  }

  .error-border {
    border: 1px solid $color-red;
  }
}
