.menu-button {
  position: fixed;
  z-index: 10;
  top: 15px;
  left: 15px;
}

.ui {
  &.input {
    flex-basis: 100%;

    & > input {
      border-radius: 0;
    }
  }

  &.segment {
    //padding: 0;
    margin: 0;
  }
}

a {
  color: $color-blue;
  transition: all 0.25s;

  &:hover {
    color: darken($color-blue, 15%);
    transition: all 0.25s;
  }
}

.link {
  text-decoration: underline;
  transition: all 0.15s;

  &:hover {
    color: $color-blue;
  }
}

.icon-with-popup {
  $this: &;

  & + #{$this} {
    margin-left: $gutter / 2;
  }
}

h1,
h2 {
  margin-bottom: 10px;
}

.wrapper {
  padding: 15px !important;
}

.page-title {
  font-size: 50px;
  line-height: 55px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .icon-with-popup {
    margin-left: 10px;

    .icon {
      vertical-align: middle;
    }
  }

  &__news {
    max-width: 80%;
    font-size: 36px;
    margin: auto;
  }
}

.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--bordered {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $color-grey--light;
  }

  &--centered {
    justify-content: center;
  }

  &--buttoned {
    @include phone {
      align-items: flex-start;

      .content-title__block {
        + div {
          text-align: right;
          font-size: 0;

          button {
            font-size: 1rem;
            padding: 0 10px;
            margin: 4px 0;
          }
        }
        + button {
          padding: 0 10px;
        }
      }

      button span {
        display: none;
      }
    }
  }

  &__wrapper {
    margin-bottom: 2em;
  }

  h1 {
    font-size: 2.1em;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    color: #333;

    > .dotted {
      width: 20em;
      max-width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: 0.5em;
      display: block;
      margin-bottom: 0.25em;
      color: $color-green;
    }
  }

  &__hint {
    margin: 0.5em 0 0;
  }

  &__tag {
    margin-bottom: 0.25em;
    background-color: $color-yellow;
    border-color: $color-yellow;
  }
}

.title-label {
  padding: 0.25em 1em;
  margin: 0 0 -1px 1em;
  display: inline-block;
  border-radius: 0.25em 0.25em 0 0;
  box-shadow: inset 0 -2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid fade_out($color-green, 0.1);
  background: fade_out($color-green, 0.3);

  &--yellow {
    border: 1px solid fade_out($color-yellow, 0.1);
    background: fade_out($color-yellow, 0.3);
  }

  &--green {
  }
}

.news-url {
  font-size: 18px;
}

.message .header {
  padding: 0;
}

.header {
  & + .tooltip {
    margin: -0.5em 0 0.5em;
    font-size: 1em;
    color: $color-grey--dark;

    &:before {
      content: '\f05a';
      font-family: Icons;
      color: $color-blue;
      margin-right: 0.5em;
    }
  }
}

.chart {
  display: flex;
  position: relative;
  justify-content: center;
}

.text-right {
  text-align: right;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-elipsis {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.statistics-card {
  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__items {
    display: flex;
  }

  &__item {
    text-align: center;
    padding: 0 2em;

    > span {
      font-size: 2em;
    }
  }

  .ant-select-selection {
    width: 150px;
  }
}
