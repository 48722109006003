$gutter: 20px !default;

// Colors
$color-blue: #2c9eb4;
$color-blue--semantic: #4183c4;
$color-grey: #aaaaa9;
$color-grey--light: #eeeee9;
$color-grey--dark: #777779;
$color-green: #b5cc19;
$color-green--semantic: #22ba46;
$color-red: #e44e31;
$color-cyan: #37cdbc;
$color-yellow: #f2be22;
$color-white: #ffffff;

// Font && Text
$font-size: 16px !default;
$font-color: $color-grey;
