$screen-xs-min: 320px;
$screen-xs-max: 767px;

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1340px;

/* Extra small devices (phones, less than 768px) */
@mixin phone {
  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    @content;
  }
}

/* Small devices (tablets, 768px and up) */
@mixin tablet {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

/* Medium devices (desktops, 992px and up) */
@mixin desktop {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

/* Large devices (large desktops, 1200px and up) */
@mixin large-desktop {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}
