.statistics-block {
  &__filter {
    display: flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .ant-calendar-picker {
      margin-bottom: 0 !important;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__table {
    .ant-table-body {
      text-align: center;
      justify-content: center;
    }
    //table {
    //  display: inline-block;
    //  width: auto;
    //}
  }

  &__rotator {
    display: flex;
    overflow-x: scroll;

    > * {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .ant-divider-inner-text {
    span {
      text-transform: uppercase;
    }
  }

  select,
  .ant-select-selection {
    min-width: 150px;
    max-width: 100%;
  }
}
