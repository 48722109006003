@import '../../../styles/variables';

.articles-list {
  &__item:not(.default) {
    &:nth-child(even):hover,
    &:hover {
      background: fade_out($color-blue, 0.8) !important;
    }
  }
}
