.sidebar {
  $this: &;
  height: 100%;
  background: #333;
  position: relative;
  padding-right: 20px;

  &--hidden {
    .sidebar__toggler {
      background: $color-grey--light;
      color: $color-grey--dark;
    }
  }

  &__toggler {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    background: rgba(0, 0, 0, 0.1);
    margin: 0;
    cursor: pointer;
    transition: 0.25s all;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: $color-white;
      transition: 0.25s all;
    }

    i {
      position: fixed;
      top: 50vh;
    }
  }

  &__menu {
    color: $color-white;
    border-radius: 0;
    background: none;
    margin: 0;
    width: auto;

    &-item {
      margin-bottom: 1em;
    }

    > .header {
      padding: 0.5em 1.1em;
      text-transform: uppercase;
      font-weight: bold;
    }

    > .item {
      &:before {
        content: none !important;
      }
    }
  }
}
